const Hero = ({ imgPath, imgAlt, children }) => {
	return (
		<section className="relative z-0 bg-black">
			<div className="grid gap-6 md:gap-4 grid-cols-1 md:grid-cols-[2fr_1fr]  items-center">
				<div className="order-2">{children}</div>
				{/* <img
					src={imgPath}
					alt={imgAlt}
					className="order-1 md:order-3 w-full h-auto  max-h-[120px] md:max-h-[300px] max-w-[120px] md:max-w-[300px] mx-auto"
					onError={({ currentTarget }) => {
						currentTarget.onerror = null
						currentTarget.src = "/images/car.png"
					}}
				/> */}
			</div>
			<div className="absolute left-1/2 top-0 w-1/2 h-full -z-10 ">
				<img
					src={imgPath}
					alt={imgAlt}
					className="absolute top-0 sm:top-1/2 sm:-translate-y-1/2 w-full -rotate-12 scale-125 opacity-25 pointer-events-none"
					onError={({ currentTarget }) => {
						currentTarget.onerror = null
						currentTarget.src = "/images/car.png"
					}}
				/>
			</div>
		</section>
	)
}

export default Hero
