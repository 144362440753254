const Sharing = () => {
	return (
		<section className=" py-8 sm:py-12  text-center">
			<div className="container max-w-[970px] mx-auto px-4  ">
				<h3 className="h3 pb-0 block md:inline-block ">Jetzt teilen</h3>
				<div className="md:inline-block md:ml-12 relative -top-1 mt-4 sm:mt-12 md:mt-0 ">
					<a
						href={
							"whatsapp://send?text=" +
							encodeURIComponent(
								"Schau mal hier! Hier findest du Schlüsselnummern von vielen verschienden Autos: " +
									window.location.href
							)
						}
						onClick={() => {
							try {
								// eslint-disable-next-line
								let dataLayer = window["dataLayer"] || []
								dataLayer.push({ event: "socialshare", clickName: "whatsapp" })
							} catch (e) {}
						}}
					>
						<svg
							className="inline-block w-[35px] h-auto mx-3 glow"
							width="34"
							height="35"
							viewBox="0 0 34 35"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M24.6666 20.8334C24.3333 20.6667 22.1666 19.6667 21.8333 19.5001C21.4999 19.3334 21.1666 19.3334 20.8333 19.6667C20.4999 20.0001 19.8333 21.0001 19.4999 21.3334C19.3333 21.6667 18.9999 21.6667 18.6666 21.5001C17.4999 21.0001 16.3333 20.3334 15.3333 19.5001C14.4999 18.6667 13.6666 17.6667 12.9999 16.6667C12.8333 16.3334 12.9999 16.0001 13.1666 15.8334C13.3333 15.6667 13.4999 15.3334 13.8333 15.1667C13.9999 15.0001 14.1666 14.6667 14.1666 14.5001C14.3333 14.3334 14.3333 14.0001 14.1666 13.8334C13.9999 13.6667 13.1666 11.6667 12.8333 10.8334C12.6666 9.66675 12.3333 9.66675 11.9999 9.66675C11.8333 9.66675 11.4999 9.66675 11.1666 9.66675C10.8333 9.66675 10.3333 10.0001 10.1666 10.1667C9.16658 11.1667 8.66658 12.3334 8.66658 13.6667C8.83325 15.1667 9.33325 16.6667 10.3333 18.0001C12.1666 20.6667 14.4999 22.8334 17.3333 24.1667C18.1666 24.5001 18.8333 24.8334 19.6666 25.0001C20.4999 25.3334 21.3333 25.3334 22.3333 25.1667C23.4999 25.0001 24.4999 24.1667 25.1666 23.1667C25.4999 22.5001 25.4999 21.8334 25.3333 21.1667C25.3333 21.1667 24.9999 21.0001 24.6666 20.8334ZM28.8333 5.66675C22.3333 -0.833252 11.8333 -0.833252 5.33325 5.66675C-8.14497e-05 11.0001 -1.00008 19.1667 2.66659 25.6667L0.333252 34.1667L9.16658 31.8334C11.6666 33.1667 14.3333 33.8334 16.9999 33.8334C26.1666 33.8334 33.4999 26.5001 33.4999 17.3334C33.6666 13.0001 31.8333 8.83341 28.8333 5.66675ZM24.3333 29.0001C22.1666 30.3334 19.6666 31.1667 16.9999 31.1667C14.4999 31.1667 12.1666 30.5001 9.99992 29.3334L9.49992 29.0001L4.33325 30.3334L5.66658 25.3334L5.33325 24.8334C1.33325 18.1667 3.33325 9.83341 9.83325 5.66675C16.3333 1.50008 24.6666 3.66675 28.6666 10.0001C32.6666 16.5001 30.8333 25.0001 24.3333 29.0001Z"
								className="fill-white"
							/>
						</svg>
					</a>
					<a
						href={"https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(window.location.href)}
						onClick={() => {
							try {
								// eslint-disable-next-line
								let dataLayer = window["dataLayer"] || []
								dataLayer.push({ event: "socialshare", clickName: "facebook" })
							} catch (e) {}
						}}
					>
						<svg
							className="inline-block w-[35px] h-auto mx-3 glow"
							width="34"
							height="35"
							viewBox="0 0 34 35"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M34 17.6039C34 8.15767 26.3888 0.5 17 0.5C7.61115 0.5 0 8.15767 0 17.6039C0 26.1408 6.21662 33.2169 14.3438 34.5V22.548H10.0273V17.6039H14.3438V13.8357C14.3438 9.54903 16.8818 7.18121 20.7649 7.18121C22.6243 7.18121 24.5703 7.51527 24.5703 7.51527V11.7244H22.4267C20.315 11.7244 19.6562 13.043 19.6562 14.3969V17.6039H24.3711L23.6174 22.548H19.6562V34.5C27.7834 33.2169 34 26.1408 34 17.6039Z"
								className="fill-white "
							/>
						</svg>
					</a>
					<a
						href={
							"mailto:?subject=" +
							encodeURIComponent("HSN TSN") +
							"&body=" +
							encodeURIComponent(
								"Schau mal hier! Hier findest du Schlüsselnummern von vielen verschienden Autos " + window.location.href
							)
						}
						onClick={() => {
							try {
								// eslint-disable-next-line
								let dataLayer = window["dataLayer"] || []
								dataLayer.push({ event: "socialshare", clickName: "email" })
							} catch (e) {}
						}}
					>
						<svg
							className="inline-block w-[35px] h-auto mx-3 glow"
							width="34"
							height="27"
							viewBox="0 0 34 27"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								d="M28.6666 0.166748H5.33325C4.00717 0.166748 2.7354 0.693532 1.79772 1.63121C0.860036 2.5689 0.333252 3.84067 0.333252 5.16675V21.8334C0.333252 23.1595 0.860036 24.4313 1.79772 25.3689C2.7354 26.3066 4.00717 26.8334 5.33325 26.8334H28.6666C29.9927 26.8334 31.2644 26.3066 32.2021 25.3689C33.1398 24.4313 33.6666 23.1595 33.6666 21.8334V5.16675C33.6666 3.84067 33.1398 2.5689 32.2021 1.63121C31.2644 0.693532 29.9927 0.166748 28.6666 0.166748ZM5.33325 3.50008H28.6666C29.1086 3.50008 29.5325 3.67568 29.8451 3.98824C30.1577 4.3008 30.3333 4.72472 30.3333 5.16675L16.9999 13.3001L3.66659 5.16675C3.66659 4.72472 3.84218 4.3008 4.15474 3.98824C4.4673 3.67568 4.89122 3.50008 5.33325 3.50008ZM30.3333 21.8334C30.3333 22.2754 30.1577 22.6994 29.8451 23.0119C29.5325 23.3245 29.1086 23.5001 28.6666 23.5001H5.33325C4.89122 23.5001 4.4673 23.3245 4.15474 23.0119C3.84218 22.6994 3.66659 22.2754 3.66659 21.8334V8.96675L16.1333 16.5834C16.3866 16.7297 16.674 16.8067 16.9666 16.8067C17.2591 16.8067 17.5466 16.7297 17.7999 16.5834L30.3333 8.96675V21.8334Z"
								className="fill-white"
							/>
						</svg>
					</a>
				</div>
			</div>
		</section>
	)
}

export default Sharing
