import axios from "axios"
import { useEffect, useState } from "react"
import Hero from "../components/Hero"

const Content = ({ contentKey }) => {
	const [page, setPage] = useState<any>(null)

	useEffect(() => {
		if (contentKey === "KFZ-Versicherungsrechner") {
			setPage(<div style={{ width: window.innerWidth }} id="tcpp-iframe-kfz" data-style="custom_grey" />)

			setTimeout(() => {
				var script = document.createElement("script")
				script.type = "text/javascript"
				script.src = "https://form.partner-versicherung.de/widgets/143006/tcpp-iframe-kfz/kfz-iframe.js"
				script.setAttribute("async", "")
				document.querySelector("#tcpp-iframe-kfz")?.appendChild(script)
			}, 1000)
		} else {
			axios
				.get("/html/" + contentKey + ".html?cv=" + Date.now())
				.then(res => {
					// console.log(res)
					if (res.status === 200 && res.data) {
						setPage(res.data)
					} else {
						setPage("Laden fehlgeschlagen.")
					}
				})
				.catch(error => {
					console.error(error)
					setPage("Laden fehlgeschlagen.")
				})
		}
		// eslint-disable-next-line
	}, [])

	return (
		<>
			<Hero imgAlt={"Auto Wert"} imgPath={"/images/car.png"}>
				<h1 className="h1">{contentKey}</h1>
			</Hero>

			<div className="bg-green u-seperator"></div>
			<div className="relative z-10 bg-black">
				{contentKey === "KFZ-Versicherungsrechner" ? (
					<div className="contentPage">{page}</div>
				) : (
					<section className="contentPage">
						<div dangerouslySetInnerHTML={{ __html: page }} />
					</section>
				)}
			</div>
		</>
	)
}
export default Content
