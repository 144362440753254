import { useAutoAnimate } from "@formkit/auto-animate/react"

interface InputProps {
	props?: any
	className?: string | null
	label?: string | null
	errMsg?: string
	hasError?: boolean
}

const ElemSelect: React.FC<InputProps> = ({ className, props, label, errMsg, hasError, children }) => {
	const [animationRef] = useAutoAnimate<HTMLDivElement>()
	return (
		<label className={"relative block " + (className || "")}>
			<select
				className={
					"pt-4 w-full peer rounded-[9px] px-4 text-xl font-Manrope text-white h-[72px] sm:h-[92px] leading-[58px] placeholder-transparent outline-0 bg-black appearance-none border-2 border-green bg-right bg-no-repeat bg-[url('/src/bgimages/dropdown.png')] " +
					(hasError ? "  js-formerror " : "")
				}
				{...props}
			>
				{children}
			</select>
			<span
				className={
					(!!props.value ? "-translate-y-4" : "") +
					" -translate-y-4 transition absolute left-0 top-0 font-Neucha text-xl px-4 h-[72px] sm:h-[92px] leading-[92px] 7m:leading-[92px]" +
					(hasError ? "  text-red " : " text-brown ")
				}
			>
				{label}
			</span>
			<div ref={animationRef}>{hasError && <div className="formErrorMsg ">{errMsg}</div>}</div>
		</label>
	)
}

export default ElemSelect
