interface PopupProps {
	close: any
}
const Popup: React.FC<PopupProps> = ({ close, children }) => {
	return (
		<div className="animate-showDialog fixed top-0 left-0 right-0 w-screen h-screen  z-20">
			<img
				src={"/images/icon-close.png"}
				alt="Schließen"
				loading="lazy"
				width="83"
				height="73"
				className=" w-[83px] cursor-pointer absolute top-0 left-0 z-10 p-4"
				onClick={close}
			/>
			<div className="delay-500 animate-showDialogInner w-full absolute right-0 top-0 h-full bg-black/90  p-8 text-center  overflow-y-auto">
				{children}
			</div>
		</div>
	)
}

export default Popup
