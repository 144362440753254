interface InputProps {
	props?: any
	className?: string | null
	label?: any
	errMsg?: string
	onClick?: any
	showLeftCount?: boolean
	hasError?: boolean
}

const ElemInput: React.FC<InputProps> = ({ className, props, label, onClick, errMsg, showLeftCount, hasError }) => {
	return (
		<label className={"relative block " + (className || "")}>
			<input
				className={
					"pt-4 w-full peer rounded-[9px] px-4 text-xl  text-white bg-black h-[72px] sm:h-[92px] leading-[72px] sm:leading-[92px] placeholder-transparent outline-0" +
					(hasError ? " border-[red] border-solid border-2 js-formerror" : " border-green border-2")
				}
				onClick={onClick}
				{...props}
			/>
			<span
				className={
					(!!props.value ? "-translate-y-4 " : "") +
					" peer-focus:-translate-y-4 transition text-white absolute left-0 top-0  font-Neucha text-lg px-4 h-[72px] sm:h-[92px] leading-[72px] sm:leading-[92px] whitespace-nowrap pointer-events-none"
				}
			>
				{label}
			</span>
			{showLeftCount && props.maxLength && (
				<div className="absolute top-6 right-3 text-white">
					{props.value.length}/{props.maxLength}
				</div>
			)}
			{hasError && <div className="text-sm mt-1 text-[red]">{errMsg}</div>}
		</label>
	)
}

export default ElemInput

// https://play.tailwindcss.com/tKldzVn3U1
