"use client"
import { Range, getTrackBackground } from "react-range"

const StaticRangeSlider = ({ value, MIN, MAX }) => {
	const STEP = 1

	const COLORS = {
		"10": "#01b022",
		"11": "#1eb11f",
		"12": "#32b21f",
		"13": "#47b220",
		"14": "#5bb31d",
		"15": "#70b41e",
		"16": "#84b51c",
		"17": "#97b51d",
		"18": "#acb61a",
		"19": "#c0b71a",
		"20": "#d5b819",
		"21": "#e9b81a",
		"22": "#f8b618",
		"23": "#f7ad18",
		"24": "#f2a11a",
		"25": "#eb9519",
		"26": "#e5891a",
		"27": "#df7d1a",
		"28": "#d9721b",
		"29": "#d46619",
		"30": "#cd591a",
		"31": "#c74e1a",
		"32": "#c1421c",
		"33": "#bb361b",
		"34": "#b1241c",
	}

	return (
		<Range
			values={value}
			step={STEP}
			min={MIN}
			max={MAX}
			onChange={values => {}}
			renderTrack={({ props, children }) => (
				<div
					onMouseDown={props.onMouseDown}
					onTouchStart={props.onTouchStart}
					style={{
						height: "15px",
						display: "flex",
						width: "100%",
						userSelect: "none",
					}}
				>
					<div
						ref={props.ref}
						style={{
							height: "17px",
							width: "100%",
							borderRadius: "8px",
							background: getTrackBackground({
								values: value,
								colors: [COLORS[value[0]], "#bbb"],
								min: MIN,
								max: MAX,
							}),
							alignSelf: "center",
						}}
					></div>
				</div>
			)}
			renderThumb={({ props }) => (
				<div
					{...props}
					style={{
						height: "22px",
						width: "22px",
						borderRadius: "50%",
						backgroundColor: "#FFF",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
					}}
				></div>
			)}
		/>
	)
}

export default StaticRangeSlider
