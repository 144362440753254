export enum ErrorMessage {
	NONE,
	EMPTY,
	UNKNOWN,
	ALLOWED,
	BLOCKED,
	NOTALLOWEDCHAR,
}

export enum TYPO {
	H1 = "H1",
	H2 = "H2",
	H3 = "H3",
	H4 = "H4",
	H5 = "H5",
	H6 = "H6",
}

export enum BUTTONSTYLE {
	DEFAULT = "default",
	SMALL = "SMALL",
}

export const MARKEN = [
	"Audi",
	"BMW",
	"Skoda",
	"Mercedes-Benz",
	"Mini",
	"Opel",
	"Volvo",
	"VW",
	"Tesla",
	// "Aiways",
	"Alfa Romeo",
	"Alpina",
	"Barkas",
	"Borgward",
	"Buick",
	"Cadillac",
	"Chevrolet",
	"Chrysler",
	"Citroën",
	"Cupra",
	"Dacia",
	// "Daewoo",
	// "DAF",
	// "Daihatsu",
	// "Datsun",
	"Dodge",
	"DS",
	"e.GO",
	"Fiat",
	"Ford",
	"Genesis",
	// "Glas",
	// "Great Wall",
	// "Hanomag",
	"Honda",
	"Hummer",
	"Hyundai",
	"Infiniti",
	// "Intreprinderea",
	"Isuzu",
	"Iveco",
	"Jaguar",
	"Jeep",
	"Kia",
	// "Kunststoff",
	"Lada",
	"Lamborghini",
	"Lancia",
	"Land Rover",
	// "Landwind",
	// "LDV",
	"Lexus",
	// "Lloyd",
	"Lynk & Co",
	"Maserati",
	"Matra",
	"Mazda",
	// "Mia",
	"Mitsubishi",
	"Morgan",
	// "Moskwitsch",
	"Nissan",
	// "Oldsmobile",
	"Peugeot",
	"Polestar",
	"Pontiac",
	"Porsche",
	// "Proton",
	"Renault",
	"Rover",
	"Saab",
	// "SAIC",
	"Seat",
	"Smart",
	// "SsangYong",
	"Subaru",
	"Suzuki",
	// "Talbot",
	// "Tata",
	"Toyota",
	// "Trabant",
	"Triumph",
	// "Vauxhall",
	// "Wartburg",
	// "Zastava",
]
export const REWRITE_MODELS = {
	"T-Klasse": "T-Klasse",
	"e-up!": "e-up!",
	"e-Golf": "e-Golf",
	"T-Cross": "T-Cross",
	"T-Roc": "T-Roc",
	"M-Reihe-(alle)": "M-Reihe (alle)",
	"X-Reihe-(alle)": "X-Reihe (alle)",
	"Z-Reihe-(alle)": "Z-Reihe (alle)",
	"e-tron": "e-tron",
	"e-tron GT": "e-tron GT",
	"Q4-e-tron": "Q4 e-tron",
	"B-Max": "B-Max",
	"C-Max": "C-Max",
	"Focus-C-Max": "Focus C-Max",
	"Grand-C-Max": "Grand C-Max",
	"Mustang Mach-E": "Mustang Mach-E",
	"S-Max": "S-Max",
	"Combo-e Life": "Combo-e Life",
	"Corsa-e": "Corsa-e",
	"Mokka-E": "Mokka-E",
	"Rocks-e": "Rocks-e",
	"Vivaro-e": "Vivaro-e",
	"Pick-up": "Pick-up",
	"E-Ulysse": "E-Ulysse",
	"CR-V": "CR-V",
	"CR-Z": "CR-Z",
	"FR-V": "FR-V",
	"HR-V": "HR-V",
	"E-Pace": "E-Pace",
	"E-Type": "E-Type",
	"F-Pace": "F-Pace",
	"F-Type": "F-Type",
	"I-Pace": "I-Pace",
	"S-Type": "S-Type",
	"X-Type": "X-Type",
	"CJ-5": "CJ-5",
	"CJ-7": "CJ-7",
	"ES-Serie-(alle)": "ES-Serie (alle)",
	"GS-Serie-(alle)": "GS-Serie (alle)",
	"GX-Serie-(alle)": "GX-Serie (alle)",
	"IS-Serie-(alle)": "IS-Serie (alle)",
	"LC-Serie-(alle)": "LC-Serie (alle)",
	"LS-Serie-(alle)": "LS-Serie (alle)",
	"LX-Serie-(alle)": "LX-Serie (alle)",
	"NX-Serie-(alle)": "NX-Serie (alle)",
	"RC-Serie-(alle)": "RC-Serie (alle)",
	"RX-Serie-(alle)": "RX-Serie (alle)",
	"SC-Serie-(alle)": "SC-Serie (alle)",
	"UX-Serie-(alle)": "UX-Serie (alle)",
	"Cabrio-Serie-(alle": "Cabrio-Serie (alle", //TODO: fehlende Klammer am ende checken
	"Clubman-Serie-(alle": "Clubman-Serie (alle",
	"Countryman-Serie-(alle": "Countryman-Serie (alle",
	"Coupe-Serie-(alle": "Coupe-Serie (alle",
	"Paceman-Serie-(alle": "Paceman-Serie (alle",
	"Roadster-Serie-(alle": "Roadster-Serie (alle",
	"Grand-Prix": "Grand-Prix",
}

export function gtmEvt(evt) {
	try {
		// eslint-disable-next-line
		let dataLayer = window["dataLayer"] || []
		dataLayer.push({
			event: evt,
		})
	} catch (e) {}
}

export const COOKIEBOT_ID = "17e4cfdb-dbbe-4f80-b8c2-85a0238ce8fb"
