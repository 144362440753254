interface AlertProps {
	children?: any
	className?: any
}

const Alert: React.FC<AlertProps> = ({ children, className }) => {
	return (
		<section
			className={"border-2 p-5 rounded-[9px] border-solid  border-[#ae2f2b] text-[#ae2f2b] bg-[#fee0e0]  " + className}
		>
			{children}
		</section>
	)
}

export default Alert
