import { REWRITE_MODELS } from "./config"

const domains = {
	"arvato-carworth.de": {
		apibase: "/",
		recaptchakey: "6LdZLUgfAAAAAHrK8jCmv2XefJNDSLj5gyeSKi55",
	},
	"www.arvato-carworth.de": {
		apibase: "/",
		recaptchakey: "6LdZLUgfAAAAAHrK8jCmv2XefJNDSLj5gyeSKi55",
	},
	"inte.fe.smarties-carworth.arvato-loyalty.io": {
		apibase: "/stage/",
		recaptchakey: "6LdZLUgfAAAAAHrK8jCmv2XefJNDSLj5gyeSKi55",
	},
	localhost: {
		apibase: "/stage/",
		recaptchakey: "6LdZLUgfAAAAAHrK8jCmv2XefJNDSLj5gyeSKi55",
	},
	"localhost:3000": {
		apibase: "/stage/",
		recaptchakey: "6LdZLUgfAAAAAHrK8jCmv2XefJNDSLj5gyeSKi55",
	},
}
export const getEnvConfig = () => {
	return domains[window.location.host] || { apibase: "", recaptchakey: "" }
}
export function convertToSlug(text: string = "") {
	return (
		text
			// .toLowerCase()
			.replace(/ /g, "-")
		// .replace(/[^\w-]+/g, "")
	)
}
export function convertToAntiSlug(text: string = "") {
	return text.replace(/-/g, " ")
}
export function normalizeModelName(text: string = "") {
	return REWRITE_MODELS[text] ? REWRITE_MODELS[text] : convertToAntiSlug(text)
}

export function scrollToTop() {
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: "smooth",
	})
}

export function capitalizeFirstLetter(str) {
	return str.charAt(0).toUpperCase() + str.slice(1)
}
