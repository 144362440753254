import { Link } from "react-router-dom"
const Footer = () => {
	return (
		<footer className="bg-black text-center py-4 z-10 relative">
			<nav>
				<ul className="ml-0  block md:inline-block">
					<li className="mx-3 block md:inline-block my-1 md:my-0">
						<Link to="/impressum" target="_blank" rel="noreferrer" className="text-brightYellow font-normal text-xs ">
							Impressum
						</Link>
					</li>
					<li className="mx-3 block md:inline-block my-1 md:my-0">
						<Link to="/datenschutz" target="_blank" rel="noreferrer" className="text-brightYellow font-normal text-xs ">
							Datenschutz
						</Link>
					</li>
					<li className="mx-3 block md:inline-block my-1 md:my-0">
						<Link
							to="/kfz-steuer-rechner"
							target="_blank"
							rel="noreferrer"
							className="text-brightYellow font-normal text-xs "
						>
							KFZ Steuer Rechner
						</Link>
					</li>
				</ul>
			</nav>
		</footer>
	)
}

export default Footer
